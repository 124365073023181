<template>
     <div class="m-solution-box">
        <div class="m-minheight">
            <h3>{{title}}</h3>
            <div class="info-box" v-for="(item,index) in content" :key="index" >
                <img :src="item" class="img" >
            </div>
          <router-link class="more" to="/MBusiness">业务介绍</router-link>
        </div>
    </div>
</template>
<script>
import  { newsImg } from '@/config/appSetting'
import { getProductCasePage } from '@/api/data'
export default {
    name:'Banner',
    components: {
    },
    data() {
      let that=this
      return  {
        content:[],
        newsImg,
        show:false,
        title:''
      }
    },
    created() {
    },
    methods: {
       
    },
    mounted() {
      let that=this
       getProductCasePage({},this.$route.params.id).then(res => {
            if (res.state) {
                if(res.data&&res.data.length>0){
                    res.data.map((item)=>{
                        that.title=item.productName
                        that.caseType=item.productType
                        if(item.productCaseImages&&item.productCaseImages.length>0){
                            item.productCaseImages.map((num)=>{
                                if(num&&that.caseType==1&&num.imageSortMark>1){
                                    let imgurl=num.imageUrl?that.newsImg+num.imageUrl:''
                                    that.content.push(imgurl)
                                }
                                if(num&&that.caseType==2&&num.imageSortMark>0){
                                    let imgurl=num.imageUrl?that.newsImg+num.imageUrl:''
                                    that.content.push(imgurl)
                                }
                            })
                        }
                    })
                }
            }
        })
    },
    beforeDestroy() {
    },
}
</script>
<style lang="less" scoped>
.m-minheight{
    min-height: 100vh;
}
.m-solution-box{
  padding: .8rem /* 60/75 */ .4rem /* 30/75 */;
  background: #fff;
  border-bottom: 1px solid #ccc;
  .info-box{
    min-height: 20vh;
    margin-bottom: .4rem /* 30/75 */;
    .img{
      width: 100%;
    }
  }
  .more{
      display: inline-block;
      width: 3.2rem /* 240/75 */;
      padding:.266667rem /* 20/75 */ 0;
      border: solid 1px #999;
      line-height: .373333rem /* 28/75 */;
      text-align: center;
      font-size: .373333rem /* 28/75 */;
      color: #666;
      margin:30px auto;
  }
}
</style>

